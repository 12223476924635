import React from 'react'

const Copy = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 2C2.895 2 2 2.895 2 4V17C2 17.5523 2.44772 18 3 18C3.55228 18 4 17.5523 4 17V5C4 4.44772 4.44772 4 5 4H17C17.5523 4 18 3.55228 18 3C18 2.44772 17.5523 2 17 2H4ZM8 6C6.895 6 6 6.895 6 8V20C6 21.105 6.895 22 8 22H20C21.105 22 22 21.105 22 20V8C22 6.895 21.105 6 20 6H8ZM8 9C8 8.44771 8.44772 8 9 8H19C19.5523 8 20 8.44772 20 9V19C20 19.5523 19.5523 20 19 20H9C8.44771 20 8 19.5523 8 19V9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Copy
