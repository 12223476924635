import React from 'react'

const CheckMark = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <g id="Icons / Outline / Basic / check-mark">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.70711 15.2929L18.2929 6.70711C18.6834 6.31658 19.3166 6.31658 19.7071 6.70711C20.0976 7.09763 20.0976 7.7308 19.7071 8.12132L10.4142 17.4142C10.0237 17.8047 9.39053 17.8047 9 17.4142L4.70711 13.1213C4.31658 12.7308 4.31658 12.0976 4.70711 11.7071C5.09763 11.3166 5.7308 11.3166 6.12132 11.7071L9.70711 15.2929Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default CheckMark
