import React from 'react'
import {variant} from 'styled-system'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import sx from './sx'
import {TYPOGRAPHY, COMMON, BORDER, LAYOUT, ICON} from './constants'
import {Icon} from './index'

import theme from './theme'

const TabButtonBase = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  transition: 100ms ease-in-out;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:active {
    transition: initial;
  }

  &:disabled {
    cursor: default;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${({disabled, active}) =>
    variant({
      variants: {
        opacityPrimary: active
          ? {
              color: disabled ? 'mono.shade1' : 'mono.light1',
              bg: disabled ? 'mono.shade2' : 'mono.shade3',
              pointerEvents: disabled ? 'none' : 'initial',
              '&:hover': {
                color: 'mono.light3',
                bg: 'opacity.shade3',
              },
              '&:active': {
                color: 'mono.light1',
                bg: 'mono.shade3',
              },
            }
          : {
              color: disabled ? 'mono.shade2' : 'mono.shade3',
              bg: disabled ? 'opacity.dark5' : 'opacity.primary10',
              pointerEvents: disabled ? 'none' : 'initial',
              '&:hover': {
                color: 'mono.dark2',
                bg: 'opacity.primary20',
              },
              '&:active': {
                color: 'mono.light1',
                bg: 'mono.shade3',
              },
            },
      },
    })}
  ${variant({
    prop: 'scale',
    variants: {
      sm: {
        py: ['6px'],
        px: ['16px'],
        borderRadius: ['8px'],
        fontSize: [1],
        lineHeight: [2],
        '& > svg': {
          size: ['16px'],
          marginRight: ['6px'],
        },
      },
      md: {
        py: ['6px', '6px', '10px'],
        px: ['16px', '16px', '20px'],
        borderRadius: ['8px', '8px', '10px'],
        fontSize: [1, 1, 2],
        lineHeight: [2],
        '& > svg': {
          size: ['16px', '16px', '20px'],
          marginRight: ['6px', '6px', '6px'],
        },
      },
      lg: {
        py: ['10px', '10px', '14px'],
        px: ['20px', '20px', '24px'],
        borderRadius: ['8px', '10px', '12px'],
        fontSize: [2, 2, 4],
        lineHeight: [2],
        '& > svg': {
          size: ['20px', '20px', '20px'],
          marginRight: ['6px', '6px', '8px'],
        },
      },
    },
  })}

  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  ${LAYOUT}
  ${ICON}
  ${sx}
`

const TabButton = (props) => {
  const {icon, active, children, ...baseProps} = props

  return (
    <TabButtonBase {...baseProps} active={active}>
      {icon && <Icon icon={icon} />}
      {children}
    </TabButtonBase>
  )
}

TabButton.defaultProps = {
  theme,
  variant: 'opacityPrimary',
  scale: 'md',
  fontFamily: 'sourceSans3',
}

TabButton.propTypes = {
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
  ...BORDER.propTypes,
  ...LAYOUT.propTypes,
  ...ICON.propTypes,
  ...sx.propTypes,
  scale: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['opacityPrimary']),
}

export default TabButton
