// @preval

const {colors: colorPrimitives, typography} = require('@uchiru/primitives')
const {lighten, rgba, desaturate} = require('polished')

const {lineHeights} = typography
const {black, white, blanket, gray, blue, green, orange, turquoise, red, yellow} = colorPrimitives
// General
const colors = {
  bodytext: black,
  black,
  white,
  blanket,
  gray,
  blue,
  green,
  orange,
  turquoise,
  red,
  yellow,
  blackfade15: 'rgba(10, 10, 10, 0.15)',
  blackfade20: 'rgba(10, 10, 10, 0.20)',
  blackfade30: 'rgba(10, 10, 10, 0.3)',
  blackfade35: 'rgba(10, 10, 10, 0.35)',
  blackfade50: 'rgba(10, 10, 10, 0.5)',
  whitefade15: 'rgba(255, 255, 255, 0.15)',
  whitefade50: 'rgba(255, 255, 255, 0.50)',
  state: {
    error: red[6],
    failure: red[6],
    pending: yellow[7],
    queued: yellow[7],
    success: green[6],
    unknown: gray[2],
  },

  border: {
    blackFade: rgba(black, 0.15),
    blue: blue[6],
    blueLight: blue[3],
    grayLight: lighten(0.03, gray[2]),
    gray: gray[2],
    grayDark: gray[4],
    grayDarker: gray[6],
    green: green[5],
    greenLight: desaturate(0.4, green[4]),
    turquoise: turquoise[6],
    red: red[6],
    redLight: desaturate(0.6, red[4]),
    white,
    whiteFade: rgba(white, 0.15),
    yellow: desaturate(0.6, yellow[4]),
  },
  counter: {
    bg: 'rgba(27, 31, 35, 0.08)',
  },
  filterList: {
    hoverBg: '#eaecef',
  },
  text: {
    gray: gray[6],
    grayLight: gray[5],
    grayDark: gray[11],
    red: red[6],
  },
  bg: {
    gray: gray[1],
    grayLight: gray[0],
    grayDark: gray[6],
    disabled: '#F3F4F6',
  },
  accent: orange[5],
  labels: {
    gray: gray[2],
    grayText: gray[11],
    grayDark: gray[6],
    grayDarkText: gray[11],
    blue: blue[6],
    blueText: blue[6],
    orange: orange[6],
    orangeText: orange[6],
    green: green[6],
    greenText: green[7],
    red: red[7],
    redText: red[7],
    yellow: yellow[7],
    yellowText: yellow[10],
    turquoise: turquoise[4],
    turquoiseText: [6],
  },
}

const breakpoints = ['576px', '768px', '992px', '1200px']

const fonts = {
  normal: fontStack([
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
  ]),
  mono: fontStack(['SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', 'Courier', 'monospace']),
}

const fontWeights = {
  light: 300,
  normal: 400,
  semibold: 500,
  bold: 600,
}

const borderWidths = [0, '1px']

const radii = ['0', '3px', '6px', '100px']

const shadows = {
  small: '0 1px 0 rgba(149, 157, 165, 0.1)',
  medium: '0 3px 6px rgba(149, 157, 165, 0.15)',
  large: '0 8px 24px rgba(149, 157, 165, 0.2)',
  'extra-large': '0 12px 48px rgba(149, 157, 165, 0.3)',
  formControl: 'inset 0px 2px 0px rgba(225, 228, 232, 0.2)',
  formControlDisabled: 'inset 0px 2px 0px rgba(220, 227, 237, 0.3)',
  formControlFocus: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 0.2em',
  primaryShadow: '0px 1px 0px rgba(20, 70, 32, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
  primaryActiveShadow: 'inset 0px 1px 0px rgba(20, 70, 32, 0.2)',
}

const sizes = {
  small: '544px',
  medium: '768px',
  large: '1012px',
  xlarge: '1280px',
}

const fontSizes = ['12px', '14px', '16px', '20px', '24px', '28px', '32px', '36px', '40px', '48px']

const space = ['0', '4px', '8px', '16px', '24px', '32px', '40px', '48px', '64px', '80px', '96px', '112px', '128px']

// Components

const buttons = {
  default: {
    color: {
      default: colors.text.grayDark,
      disabled: gray[4],
    },
    border: {
      default: 'rgba(10, 10, 10, 0.12)',
      active: colors.border.grayDark,
      disabled: colors.border.grayLight,
    },
    bg: {
      default: colors.bg.grayLight,
      hover: '#F3F4F6', // custom gray
      active: '#edeff2', //custom gray
      disabled: colors.bg.grayDark,
    },
    shadow: {
      default: '0px 1px 0px rgba(27, 31, 35, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
      hover: '0px 1px 0px rgba(209, 213, 218, 0.2), inset 0px 2px 0px rgba(255, 255, 255, 0.1)',
      active: 'inset 0px 2px 0px rgba(149, 157, 165, 0.1)',
      focus: '0 0 0 3px rgba(3, 102, 214, 0.3)',
    },
  },
  primary: {
    color: {
      default: white,
      disabled: white,
    },
    border: {
      default: green[6],
      hover: green[7],
      active: green[8],
      disabled: colors.bg.grayDark,
    },
    bg: {
      default: green[6],
      focus: green[7],
      hover: green[7],
      active: green[8],
      disabled: colors.bg.grayDark,
    },
    shadow: {
      default: ' 0px 1px 0px rgba(20, 70, 32, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      active: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      hover: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      focus: '0 0 0 3px #94D3A2',
    },
  },
  danger: {
    color: {
      default: colors.text.red,
      hover: white,
      active: white,
      disabled: 'rgba(203,36,49, .5)', // custom?
    },
    border: {
      default: colors.border.gray,
      hover: 'rgba(27, 31, 35, 0.15)',
      active: 'rgba(27, 31, 35, 0.15)',
    },
    bg: {
      default: gray[0],
      hover: red[6],
      active: '#be222e', // 2% darker than hover bg
      disabled: '#F3F4F6',
    },
    shadow: {
      default: '0px 1px 0px rgba(149, 157, 165, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
      active: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      hover: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      focus: '0 0 0 3px rgba(203, 36, 49, 0.4)',
    },
  },
  outline: {
    color: {
      default: blue[5],
      hover: white,
      active: white,
      disabled: gray[4],
    },
    border: {
      default: gray[2], //border-gray
      hover: 'rgba(27, 31, 35, 0.15)',
      active: 'rgba(27, 31, 35, 0.15)',
    },
    bg: {
      default: gray[0],
      hover: blue[5],
      active: '#035fc7', // 2% darker than hover bg
      disabled: '#F3F4F6',
    },
    shadow: {
      default: '0px 1px 0px rgba(149, 157, 165, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.25)',
      active: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      hover: '0px 1px 0px rgba(27, 31, 35, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
      focus: '0 0 0 3px rgba(3, 102, 214, 0.3)',
    },
  },
}

const flash = {
  default: {
    backgroundColor: blue[1],
    borderColor: 'rgba(4, 66, 137, 0.2)',
  },
  success: {
    backgroundColor: green[1],
    borderColor: 'rgba(23, 111, 44, 0.2)',
  },
  danger: {
    backgroundColor: '#FFE3E6',
    borderColor: 'rgba(158, 28, 35, 0.2)',
  },
  warning: {
    backgroundColor: yellow[1],
    borderColor: 'rgba(176, 136, 0, 0.2)',
  },
}

// this has to be separated from the flash object since we have to use an svg selector to style the icon color
const flashIcon = {
  default: 'rgba(4, 66, 137, 0.6)',
  success: 'rgba(23, 111, 44, 0.8)',
  danger: 'rgba(158, 28, 35, 0.6)',
  warning: yellow[8],
}

const popovers = {
  colors: {
    caret: 'rgba(27, 31, 35, 0.15)',
  },
}

const pagination = {
  borderRadius: radii[2],
  spaceBetween: space[1],
  colors: {
    normal: {
      fg: colors.gray[11],
    },
    disabled: {
      fg: colors.gray[3],
      border: 'transparent',
    },
    hover: {
      border: colors.border.grayLight,
    },
    selected: {
      fg: colors.white,
      bg: colors.blue[5],
      border: 'transparent',
    },
    active: {
      border: colors.border.grayLight,
    },
    nextPrevious: {
      fg: colors.blue[5],
    },
  },
}

const stateLabels = {
  sizes: {
    small: {
      padding: `${space[1]} ${space[2]}`,
      fontSize: fontSizes[0],
    },
    normal: {
      padding: `${space[2]} 12px`,
      fontSize: fontSizes[1],
    },
  },

  status: {
    issueClosed: {
      backgroundColor: red[5],
    },
    pullClosed: {
      backgroundColor: red[5],
    },
    pullMerged: {
      backgroundColor: turquoise[5],
    },
    issueOpened: {
      backgroundColor: '#159739', // custom green
    },
    pullOpened: {
      backgroundColor: '#159739', // custom green
    },
    draft: {
      backgroundColor: gray[5],
    },
  },
}

const theme = {
  // General
  borderWidths,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,

  // Components
  buttons,
  pagination,
  popovers,
  flash,
  flashIcon,
  stateLabels,
}

module.exports = {
  theme,
  colors,
}

function fontStack(fonts) {
  return fonts.map((font) => (font.includes(' ') ? `"${font}"` : font)).join(', ')
}
