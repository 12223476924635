import React from 'react'

const ExclamationMarkCircled = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11ZM20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11ZM11.0003 16.9983C11.5528 16.9983 12.0007 16.5506 12.0007 15.9983C12.0007 15.4461 11.5528 14.9983 11.0003 14.9983C10.4479 14.9983 10 15.4461 10 15.9983C10 16.5506 10.4479 16.9983 11.0003 16.9983ZM12.0036 5.99835C12.0036 5.44606 11.5556 4.99835 11.0033 4.99835C10.451 4.99835 10.003 5.44606 10.003 5.99835V11.9983C10.003 12.5506 10.451 12.9983 11.0033 12.9983C11.5556 12.9983 12.0036 12.5506 12.0036 11.9983V5.99835Z"
      />
    </svg>
  )
}

export default ExclamationMarkCircled
