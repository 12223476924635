import React from 'react'

const ChevronRight = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 2.5L15 10L7.5 17.5" stroke="currentColor" strokeWidth="3" />
    </svg>
  )
}

export default ChevronRight
