import React from 'react'

const Python = (props) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6 3.59998C12.9492 3.59998 10.8 5.74918 10.8 8.39998V9.59998H16.8C17.4624 9.59998 18 10.1376 18 10.8C18 11.4624 17.4624 12 16.8 12H10.8H9.59998H8.39998C5.74918 12 3.59998 14.1492 3.59998 16.8V21.6C3.59998 24.2508 5.74918 26.4 8.39998 26.4H9.59998V20.4C9.59998 18.4152 11.2152 16.8 13.2 16.8H22.8C23.4624 16.8 24 16.2624 24 15.6V8.39998C24 5.74918 21.8508 3.59998 19.2 3.59998H15.6ZM14.4 5.99998C15.0624 5.99998 15.6 6.53758 15.6 7.19998C15.6 7.86238 15.0624 8.39998 14.4 8.39998C13.7376 8.39998 13.2 7.86238 13.2 7.19998C13.2 6.53758 13.7376 5.99998 14.4 5.99998ZM26.4 9.59998V15.6C26.4 17.5848 24.7848 19.2 22.8 19.2H13.2C12.5376 19.2 12 19.7376 12 20.4V27.6C12 30.2508 14.1492 32.4 16.8 32.4H20.4C23.0508 32.4 25.2 30.2508 25.2 27.6V26.4H19.2C18.5376 26.4 18 25.8624 18 25.2C18 24.5376 18.5376 24 19.2 24H25.2H26.4H27.6C30.2508 24 32.4 21.8508 32.4 19.2V14.4C32.4 11.7492 30.2508 9.59998 27.6 9.59998H26.4ZM21.6 27.6C22.2624 27.6 22.8 28.1376 22.8 28.8C22.8 29.4624 22.2624 30 21.6 30C20.9376 30 20.4 29.4624 20.4 28.8C20.4 28.1376 20.9376 27.6 21.6 27.6Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default Python
