import * as uchiruComponents from '@uchiru/components'
import * as doctocatComponents from '@uchiru/gatsby-theme-doctocat'
import Octicon, {
  Check,
  Zap,
  X,
  Search,
  PrimitiveDot,
  Octoface,
  Person,
  Mail,
  GitCommit,
  Flame
} from '@primer/octicons-react'
import State from '../../../components/State'

export default {
  ...uchiruComponents,
  ...doctocatComponents,
  State,
  Octicon,
  Check,
  Search,
  Zap,
  X,
  PrimitiveDot,
  Octoface,
  Person,
  Mail,
  GitCommit,
  Flame
}
