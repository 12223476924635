import { colors } from '@uchiru/primitives';
import { Box, Flex, Heading, Link, StyledOcticon } from '@primer/components';
import { Link as LinkIcon } from '@primer/octicons-react';
import { palettes, variables } from "../../../../src/color-variables";
import { PaletteTable, PaletteCell, overlayColor } from "../../../../src/color-system";
import React from 'react';
export default {
  colors,
  Box,
  Flex,
  Heading,
  Link,
  StyledOcticon,
  LinkIcon,
  palettes,
  variables,
  PaletteTable,
  PaletteCell,
  overlayColor,
  React
};