import React from 'react'
import {variant} from 'styled-system'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import sx from './sx'
import {TYPOGRAPHY, COMMON, BORDER, LAYOUT, ICON} from './constants'
import {Icon} from './index'

import theme from './theme'

const ButtonBase = styled.button`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  transition: 100ms ease-in-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transition: initial;
  }

  &&& {
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${({disabled}) =>
    variant({
      variants: {
        mainPrimary: {
          color: disabled ? 'mono.light2' : 'mono.light1',
          bg: disabled ? 'mono.shade1' : 'color.primaryBase',
          pointerEvents: disabled ? 'none' : 'initial',
          '&:hover': {
            color: 'mono.light2',
            bg: 'color.primaryDark',
          },
          '&:active': {
            color: 'mono.light3',
            bg: 'color.primaryDarker',
          },
        },
        mainSecondary: {
          color: disabled ? 'mono.shade2' : 'color.primaryBase',
          bg: disabled ? 'opacity.dark5' : 'opacity.primary5',
          pointerEvents: disabled ? 'none' : 'initial',
          '&:hover': {
            color: 'color.primaryDark',
            bg: 'opacity.primary10',
          },
          '&:active': {
            color: 'color.primaryDarker',
            bg: 'opacity.primary20',
          },
        },
        whitePrimary: {
          color: disabled ? 'mono.shade2' : 'color.primaryBase',
          bg: disabled ? 'mono.light3' : 'mono.light1',
          pointerEvents: disabled ? 'none' : 'initial',
          '&:hover': {
            color: 'color.primaryDark',
            bg: 'mono.light3',
          },
          '&:active': {
            color: 'color.primaryDarker',
            bg: 'mono.shade1',
          },
        },
        whiteSecondary: {
          color: disabled ? 'mono.shade2' : 'mono.light1',
          bg: disabled ? 'opacity.light5' : 'opacity.light20',
          pointerEvents: disabled ? 'none' : 'initial',
          '&:hover': {
            color: 'mono.light2',
            bg: 'opacity.light10',
          },
          '&:active': {
            color: 'mono.light3',
            bg: 'opacity.light5',
          },
        },
        dangerPrimary: {
          color: disabled ? 'mono.light2' : 'mono.light1',
          bg: disabled ? 'mono.shade1' : 'color.errorBase',
          pointerEvents: disabled ? 'none' : 'initial',
          '&:hover': {
            color: 'color.light2',
            bg: 'mono.errorDark',
          },
          '&:active': {
            color: 'color.light3',
            bg: 'mono.errorDarker',
          },
        },
      },
    })}
  ${({onlyIconMode}) =>
    variant({
      prop: 'scale',
      variants: {
        sm: {
          py: ['8px'],
          px: onlyIconMode ? ['8px'] : ['16px'],
          borderRadius: ['10px'],
          fontSize: [1],
          lineHeight: [1],
          '& > svg': {
            size: ['16px'],
            marginRight: onlyIconMode ? 'initial' : ['6px'],
          },
        },
        md: {
          py: ['10px'],
          px: onlyIconMode ? ['10px'] : ['20px'],
          borderRadius: ['10px'],
          fontSize: [2],
          lineHeight: [2],
          '& > svg': {
            size: ['20px'],
            marginRight: onlyIconMode ? 'initial' : ['6px'],
          },
        },
        lg: {
          py: ['10px', '10px', '10px', '14px'],
          px: onlyIconMode ? ['10px', '10px', '10px', '14px'] : ['20px', '20px', '20px', '24px'],
          borderRadius: ['10px', '10px', '10px', '12px'],
          fontSize: [2, 2, 2, 4],
          lineHeight: [2],
          '& > svg': {
            size: ['20px', '20px', '20px', '20px'],
            marginRight: onlyIconMode ? 'initial' : ['6px', '6px', '6px', '8px'],
          },
        },
        xl: {
          py: ['14px', '14px', '14px', '18px'],
          px: onlyIconMode ? ['14px', '14px', '14px', '18px'] : ['24px', '24px', '24px', '28px'],
          borderRadius: ['12px', '12px', '12px', '16px'],
          fontSize: [4, 4, 4, '22px'],
          lineHeight: [2, 2, 2, 3],
          '& > svg': {
            size: ['20px', '20px', '20px', '24px'],
            marginRight: onlyIconMode ? 'initial' : ['8px', '8px', '8px', '10px'],
          },
        },
      },
    })}

  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  ${LAYOUT}
  ${ICON}
  ${sx}
`

const Button = (props) => {
  const {icon, children, ...baseProps} = props

  const onlyIconMode = !children

  return (
    <ButtonBase {...baseProps} onlyIconMode={onlyIconMode}>
      {icon && <Icon icon={icon} />}
      {children}
    </ButtonBase>
  )
}

Button.defaultProps = {
  theme,
  variant: 'mainPrimary',
  scale: 'md',
  fontFamily: 'sourceSans3',
  display: {_: 'flex', sm: 'inline-flex'},
  width: {_: '100%', sm: 'auto'},
}

Button.propTypes = {
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
  ...BORDER.propTypes,
  ...LAYOUT.propTypes,
  ...ICON.propTypes,
  ...sx.propTypes,
  scale: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  variant: PropTypes.oneOf(['mainPrimary', 'mainSecondary', 'whitePrimary', 'whiteSecondary', 'dangerPrimary']),
}

export default Button
