import ChevronLeft from './icons/ChevronLeft.js'
import ChevronRight from './icons/ChevronRight.js'
import PlusCircled from './icons/PlusCircled.js'
import ChevronLeftThin from './icons/ChevronLeftThin.js'
import ChevronRightThin from './icons/ChevronRightThin.js'
import ProductCpp from './icons/Products/Cpp.js'
import ProductPython from './icons/Products/Python.js'
import Sun from './icons/Sun.js'
import Bin from './icons/Bin.js'
import ExclamationMarkCircled from './icons/ExclamationMarkCircled.js'
import Copy from './icons/Copy.js'
import CheckMark from './icons/CheckMark.js'
import ArrowLeft from './icons/ArrowLeft.js'

export const CHEVRON_LEFT = 'chevron-left'
export const CHEVRON_RIGHT = 'chevron-right'
export const CHEVRON_LEFT_THIN = 'chevron-left-thin'
export const CHEVRON_RIGHT_THIN = 'chevron-right-thin'
export const PLUS_CIRCLED = 'plus-circled'

export const PRODUCT_PYTHON = 'product-python'
export const PRODUCT_CPP = 'product-cpp'

export const SUN = 'sun'
export const BIN = 'bin'

export const EXCLAMATION_MARK_CIRCLED = 'exclamation-mark-circled'
export const COPY = 'copy'
export const CHECK_MARK = 'check-mark'
export const ARROW_LEFT = 'arrow-left'

export const ICONS_LIST = [
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CHEVRON_LEFT_THIN,
  CHEVRON_RIGHT_THIN,
  PLUS_CIRCLED,
  PRODUCT_PYTHON,
  PRODUCT_CPP,
  SUN,
  BIN,
  EXCLAMATION_MARK_CIRCLED,
  COPY,
  CHECK_MARK,
  ARROW_LEFT,
]

export const Icons = {
  [CHEVRON_LEFT]: ChevronLeft,
  [CHEVRON_RIGHT]: ChevronRight,
  [CHEVRON_LEFT_THIN]: ChevronLeftThin,
  [CHEVRON_RIGHT_THIN]: ChevronRightThin,
  [PLUS_CIRCLED]: PlusCircled,
  [PRODUCT_PYTHON]: ProductPython,
  [PRODUCT_CPP]: ProductCpp,
  [SUN]: Sun,
  [BIN]: Bin,
  [EXCLAMATION_MARK_CIRCLED]: ExclamationMarkCircled,
  [COPY]: Copy,
  [CHECK_MARK]: CheckMark,
  [ARROW_LEFT]: ArrowLeft,
}
