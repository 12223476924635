import React from 'react'
import {variant} from 'styled-system'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import {Icon} from './index'

import sx from './sx'
import {TYPOGRAPHY, COMMON, BORDER, LAYOUT, ICON, POSITION} from './constants'

import theme from './theme'

const RoundButtonBase = styled.button.attrs((props) => {
  return {
    theme: {
      ...props.theme,
      shadows: [
        '0px 5px 15px -5px rgba(18, 18, 23, 0.2)',
        '0px 10px 20px -5px rgba(18, 18, 23, 0.2)',
        '0px 10px 30px -5px rgba(18, 18, 23, 0.2)',
        '0px 10px 35px -10px rgba(18, 18, 23, 0.3)',
      ],
    },
  }
})`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  text-decoration: none;
  transition: 100ms ease-in-out;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;

  &:active {
    transition: initial;
  }

  &:disabled {
    cursor: default;
  }
  
  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${variant({
    prop: 'scale',
    variants: {
      sm: {
        size: ['32px'],
        borderWidth: ['1px'],
        boxShadow: [0],
        '& > svg': {
          size: ['16px'],
        },
      },
      md: {
        size: ['32px', '32px', '32px', '40px'],
        borderWidth: ['1px', '1px', '1px', '2px'],
        boxShadow: [0, 0, 0, 1],
        '& > svg': {
          size: ['16px', '16px', '16px', '20px'],
        },
      },
      lg: {
        size: ['40px', '40px', '40px', '48px'],
        borderWidth: ['2px', '2px', '2px', '2px'],
        boxShadow: [1, 1, 1, 2],
        '& > svg': {
          size: ['20px', '20px', '20px', '24px'],
        },
      },
      xl: {
        size: ['48px', '48px', '48px', '60px'],
        borderWidth: ['2px', '2px', '2px', '3px'],
        boxShadow: [2, 2, 2, 3],
        '& > svg': {
          size: ['24px', '24px', '24px', '32px'],
        },
      },
    },
  })}
  ${variant({
    variants: {
      filled: {
        color: 'color.primaryBase',
        bg: 'opacity.primary5',
        boxShadow: ['none', 'none', 'none', 'none'],
        '&:hover': {
          color: 'color.primaryDark',
          bg: 'opacity.primary10',
        },
        '&:active': {
          color: 'color.primaryDarker',
          bg: 'opacity.primary20',
        },
        '&:disabled': {
          color: 'mono.shade2',
          bg: 'opacity.dark5',
        },
      },
      outline: {
        color: 'color.primaryBase',
        borderColor: 'color.primaryBase',
        borderStyle: 'solid',
        boxShadow: ['none', 'none', 'none', 'none'],
        bg: 'mono.light1',
        '&:hover': {
          color: 'color.primaryDark',
          borderColor: 'color.primaryDark',
          bg: 'mono.light3',
        },
        '&:active': {
          color: 'color.primaryDarker',
          borderColor: 'color.primaryDarker',
          bg: 'mono.shade1',
        },
        '&:disabled': {
          color: 'mono.shade2',
          borderColor: 'mono.shade2',
          bg: 'mono.light3',
        },
      },
      lifted: {
        color: 'color.primaryBase',
        bg: 'mono.light1',
        position: 'relative',
        zIndex: 1,
        '&:hover': {
          color: 'color.primaryDark',
          bg: 'mono.light3',
        },
        '&:active': {
          color: 'color.primaryDarker',
          bg: 'mono.shade1',
          boxShadow: ['none', 'none', 'none', 'none'],
        },
        '&:disabled': {
          color: 'mono.shade2',
          bg: 'mono.light3',
        },
      },
    },
  })}

  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  ${LAYOUT}
  ${POSITION}
  ${sx}
`

const RoundButton = (props) => {
  const {icon, ...baseProps} = props

  return (
    <RoundButtonBase {...baseProps}>
      <Icon icon={icon} />
    </RoundButtonBase>
  )
}

RoundButton.defaultProps = {
  theme,
  variant: 'filled',
  scale: 'md',
}

RoundButton.propTypes = {
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
  ...BORDER.propTypes,
  ...LAYOUT.propTypes,
  ...POSITION.propTypes,
  ...sx.propTypes,
  icon: ICON.propTypes.icon.isRequired,
  scale: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  variant: PropTypes.oneOf(['filled', 'outline', 'lifted']),
}

export default RoundButton
