import {FONT_FALLBACK} from './constants'
const themeExport = require('./theme-preval')
const {theme, colors: oldColors} = themeExport

// New theme params
const colors = {
  mono: {
    dark1: '#121217',
    dark2: '#3B3B4C',
    shade1: '#CACAD6',
    shade2: '#9696AD',
    shade3: '#646480',
    light1: '#FFFFFF',
    light2: '#F8F8FC',
    light3: '#F2F2F9',
  },
  color: {
    primaryLighter: '#829CFC',
    primaryLight: '#4153E2',
    primaryBase: '#3848C9',
    primaryDark: '#2A3AB7',
    primaryDarker: '#27349A',
    errorBase: '#F53906',
    errorDark: '#D7390D',
    errorDarker: '#AC300E',
    pendingBase: '#FFBF00',
    positiveBase: '#21BC13',
    pendingDark: '#FF9E00',
  },
  opacity: {
    primary5: 'rgba(56, 72, 201, 0.05)',
    primary10: 'rgba(56, 72, 201, 0.1)',
    primary20: 'rgba(56, 72, 201, 0.2)',
    primary40: 'rgba(56, 72, 201, 0.4)',
    dark5: 'rgba(18, 18, 23, 0.05)',
    dark10: 'rgba(18, 18, 23, 0.1)',
    dark20: 'rgba(18, 18, 23, 0.2)',
    dark40: 'rgba(18, 18, 23, 0.4)',
    light5: 'rgba(255, 255, 255, 0.05)',
    light10: 'rgba(255, 255, 255, 0.1)',
    light20: 'rgba(255, 255, 255, 0.2)',
    light40: 'rgba(255, 255, 255, 0.4)',
    blanket: 'rgba(100, 100, 128, 0.8)',
    blanketDark: 'rgba(59, 59, 76, 0.8)',
    pendingBase10: 'rgba(255, 191, 0, 0.1)',
    positiveBase10: 'rgba(33, 188, 19, 0.1)',
    errorBase10: 'rgba(245, 57, 6, 0.1)',
  },
}
const fontSizes = [12, 14, 16, 18, 20, 24, 28, 36, 44, 52]
const lineHeights = [1, '16px', '20px', '24px', '28px', '32px', '40px', '48px', '56px']

const fontWeights = [400, 600]
fontWeights.regular = fontWeights[0]
fontWeights.bold = fontWeights[1]

const fonts = {
  sourceSans3: `"Source Sans 3", ${FONT_FALLBACK}`,
  nunito: `Nunito, ${FONT_FALLBACK}`,
}

const breakpoints = ['320px', '412px', '640px', '768px', '1024px', '1280px']
breakpoints.xxs = breakpoints[0]
breakpoints.xs = breakpoints[1]
breakpoints.sm = breakpoints[2]
breakpoints.md = breakpoints[3]
breakpoints.lg = breakpoints[4]
breakpoints.xl = breakpoints[5]

theme.colors = colors
theme.fontSizes = fontSizes
theme.lineHeights = lineHeights
theme.fontWeights = fontWeights
theme.fonts = fonts
theme.breakpoints = breakpoints

export default theme
export {oldColors as colors}
