import {BaseStyles, Box, theme} from '@uchiru/components'
import React from 'react'
import {ThemeProvider} from 'styled-components'

// Users can shadow this file to wrap live previews.
// This is useful for applying global styles.
function LivePreviewWrapper({children}) {
  return (
    <ThemeProvider theme={theme}>
      <BaseStyles>
        <Box p={3}>{children}</Box>
      </BaseStyles>
    </ThemeProvider>
  )
}

export default LivePreviewWrapper
