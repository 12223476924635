import {variant} from 'styled-system'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import sx from '../sx'
import {TYPOGRAPHY, COMMON} from '../constants'

import theme from '../theme'

const Heading = styled.div.attrs((props) => {
  return {
    variant: props.variant || props.as,
  }
})`
  margin: 0;
  padding: 0;

  ${variant({
    variants: {
      title: {
        fontSize: [6, 6, 6, 8],
        lineHeight: [5, 5, 5, 7],
      },
      h1: {
        fontSize: [5, 5, 5, 7],
        lineHeight: [4, 4, 4, 6],
      },
      h2: {
        fontSize: [4, 4, 4, 6],
        lineHeight: [3, 3, 3, 5],
      },
      h3: {
        fontSize: [3, 3, 3, 5],
        lineHeight: [2, 2, 2, 4],
      },
      h4: {
        fontSize: [2, 2, 2, 3],
        lineHeight: [2, 2, 2, 2],
      },
    },
  })}
 
  ${COMMON}
  ${TYPOGRAPHY}
  ${sx}
`

Heading.defaultProps = {
  color: 'mono.dark1',
  fontWeight: 'bold',
  fontFamily: 'sourceSans3',
  theme,
}

Heading.propTypes = {
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
  ...sx.propTypes,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
  variant: PropTypes.oneOf(['title', 'h1', 'h2', 'h3', 'h4']),
}

export default Heading
