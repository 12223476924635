// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-index-md": () => import("../content/index.md" /* webpackChunkName: "component---content-index-md" */),
  "component---content-about-getting-started-md": () => import("../content/about/getting-started.md" /* webpackChunkName: "component---content-about-getting-started-md" */),
  "component---content-about-core-concepts-md": () => import("../content/about/core-concepts.md" /* webpackChunkName: "component---content-about-core-concepts-md" */),
  "component---content-about-overriding-styles-mdx": () => import("../content/about/overriding-styles.mdx" /* webpackChunkName: "component---content-about-overriding-styles-mdx" */),
  "component---content-about-philosophy-md": () => import("../content/about/philosophy.md" /* webpackChunkName: "component---content-about-philosophy-md" */),
  "component---content-about-system-props-mdx": () => import("../content/about/system-props.mdx" /* webpackChunkName: "component---content-about-system-props-mdx" */),
  "component---content-about-uchiru-theme-md": () => import("../content/about/uchiru-theme.md" /* webpackChunkName: "component---content-about-uchiru-theme-md" */),
  "component---content-atoms-avatar-mdx": () => import("../content/atoms/Avatar.mdx" /* webpackChunkName: "component---content-atoms-avatar-mdx" */),
  "component---content-atoms-avatar-stack-mdx": () => import("../content/atoms/AvatarStack.mdx" /* webpackChunkName: "component---content-atoms-avatar-stack-mdx" */),
  "component---content-atoms-border-box-md": () => import("../content/atoms/BorderBox.md" /* webpackChunkName: "component---content-atoms-border-box-md" */),
  "component---content-atoms-box-md": () => import("../content/atoms/Box.md" /* webpackChunkName: "component---content-atoms-box-md" */),
  "component---content-atoms-branch-name-md": () => import("../content/atoms/BranchName.md" /* webpackChunkName: "component---content-atoms-branch-name-md" */),
  "component---content-atoms-breadcrumbs-md": () => import("../content/atoms/Breadcrumbs.md" /* webpackChunkName: "component---content-atoms-breadcrumbs-md" */),
  "component---content-atoms-circle-badge-md": () => import("../content/atoms/CircleBadge.md" /* webpackChunkName: "component---content-atoms-circle-badge-md" */),
  "component---content-atoms-circle-octicon-md": () => import("../content/atoms/CircleOcticon.md" /* webpackChunkName: "component---content-atoms-circle-octicon-md" */),
  "component---content-atoms-counter-label-md": () => import("../content/atoms/CounterLabel.md" /* webpackChunkName: "component---content-atoms-counter-label-md" */),
  "component---content-atoms-details-md": () => import("../content/atoms/Details.md" /* webpackChunkName: "component---content-atoms-details-md" */),
  "component---content-atoms-dialog-md": () => import("../content/atoms/Dialog.md" /* webpackChunkName: "component---content-atoms-dialog-md" */),
  "component---content-atoms-dropdown-md": () => import("../content/atoms/Dropdown.md" /* webpackChunkName: "component---content-atoms-dropdown-md" */),
  "component---content-atoms-filter-list-md": () => import("../content/atoms/FilterList.md" /* webpackChunkName: "component---content-atoms-filter-list-md" */),
  "component---content-atoms-filtered-search-md": () => import("../content/atoms/FilteredSearch.md" /* webpackChunkName: "component---content-atoms-filtered-search-md" */),
  "component---content-atoms-flash-md": () => import("../content/atoms/Flash.md" /* webpackChunkName: "component---content-atoms-flash-md" */),
  "component---content-atoms-flex-md": () => import("../content/atoms/Flex.md" /* webpackChunkName: "component---content-atoms-flex-md" */),
  "component---content-atoms-grid-md": () => import("../content/atoms/Grid.md" /* webpackChunkName: "component---content-atoms-grid-md" */),
  "component---content-atoms-heading-md": () => import("../content/atoms/Heading.md" /* webpackChunkName: "component---content-atoms-heading-md" */),
  "component---content-atoms-icon-md": () => import("../content/atoms/Icon.md" /* webpackChunkName: "component---content-atoms-icon-md" */),
  "component---content-atoms-label-md": () => import("../content/atoms/Label.md" /* webpackChunkName: "component---content-atoms-label-md" */),
  "component---content-atoms-link-md": () => import("../content/atoms/Link.md" /* webpackChunkName: "component---content-atoms-link-md" */),
  "component---content-atoms-label-group-md": () => import("../content/atoms/LabelGroup.md" /* webpackChunkName: "component---content-atoms-label-group-md" */),
  "component---content-atoms-pagination-md": () => import("../content/atoms/Pagination.md" /* webpackChunkName: "component---content-atoms-pagination-md" */),
  "component---content-atoms-pointer-box-md": () => import("../content/atoms/PointerBox.md" /* webpackChunkName: "component---content-atoms-pointer-box-md" */),
  "component---content-atoms-popover-md": () => import("../content/atoms/Popover.md" /* webpackChunkName: "component---content-atoms-popover-md" */),
  "component---content-atoms-position-md": () => import("../content/atoms/Position.md" /* webpackChunkName: "component---content-atoms-position-md" */),
  "component---content-atoms-progress-bar-mdx": () => import("../content/atoms/ProgressBar.mdx" /* webpackChunkName: "component---content-atoms-progress-bar-mdx" */),
  "component---content-atoms-round-button-md": () => import("../content/atoms/RoundButton.md" /* webpackChunkName: "component---content-atoms-round-button-md" */),
  "component---content-atoms-select-menu-md": () => import("../content/atoms/SelectMenu.md" /* webpackChunkName: "component---content-atoms-select-menu-md" */),
  "component---content-atoms-side-nav-md": () => import("../content/atoms/SideNav.md" /* webpackChunkName: "component---content-atoms-side-nav-md" */),
  "component---content-atoms-state-label-md": () => import("../content/atoms/StateLabel.md" /* webpackChunkName: "component---content-atoms-state-label-md" */),
  "component---content-atoms-styled-octicon-md": () => import("../content/atoms/StyledOcticon.md" /* webpackChunkName: "component---content-atoms-styled-octicon-md" */),
  "component---content-atoms-sub-nav-md": () => import("../content/atoms/SubNav.md" /* webpackChunkName: "component---content-atoms-sub-nav-md" */),
  "component---content-atoms-tab-nav-md": () => import("../content/atoms/TabNav.md" /* webpackChunkName: "component---content-atoms-tab-nav-md" */),
  "component---content-atoms-tab-button-md": () => import("../content/atoms/TabButton.md" /* webpackChunkName: "component---content-atoms-tab-button-md" */),
  "component---content-atoms-tab-switcher-group-md": () => import("../content/atoms/TabSwitcherGroup.md" /* webpackChunkName: "component---content-atoms-tab-switcher-group-md" */),
  "component---content-atoms-text-md": () => import("../content/atoms/Text.md" /* webpackChunkName: "component---content-atoms-text-md" */),
  "component---content-atoms-tooltip-md": () => import("../content/atoms/Tooltip.md" /* webpackChunkName: "component---content-atoms-tooltip-md" */),
  "component---content-atoms-truncate-md": () => import("../content/atoms/Truncate.md" /* webpackChunkName: "component---content-atoms-truncate-md" */),
  "component---content-forms-button-md": () => import("../content/forms/Button.md" /* webpackChunkName: "component---content-forms-button-md" */),
  "component---content-other-underline-nav-md": () => import("../content/other/UnderlineNav.md" /* webpackChunkName: "component---content-other-underline-nav-md" */),
  "component---content-overlay-confirmation-popup-md": () => import("../content/overlay/ConfirmationPopup.md" /* webpackChunkName: "component---content-overlay-confirmation-popup-md" */),
  "component---content-support-breakpoints-md": () => import("../content/support/breakpoints.md" /* webpackChunkName: "component---content-support-breakpoints-md" */),
  "component---content-support-color-system-mdx": () => import("../content/support/color-system.mdx" /* webpackChunkName: "component---content-support-color-system-mdx" */),
  "component---content-support-marketing-variables-md": () => import("../content/support/marketing-variables.md" /* webpackChunkName: "component---content-support-marketing-variables-md" */),
  "component---content-support-spacing-md": () => import("../content/support/spacing.md" /* webpackChunkName: "component---content-support-spacing-md" */),
  "component---content-support-typography-md": () => import("../content/support/typography.md" /* webpackChunkName: "component---content-support-typography-md" */),
  "component---content-atoms-text-input-md": () => import("../content/atoms/TextInput.md" /* webpackChunkName: "component---content-atoms-text-input-md" */),
  "component---content-atoms-timeline-md": () => import("../content/atoms/Timeline.md" /* webpackChunkName: "component---content-atoms-timeline-md" */),
  "component---content-atoms-tip-md": () => import("../content/atoms/Tip.md" /* webpackChunkName: "component---content-atoms-tip-md" */)
}

