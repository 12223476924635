import React from 'react'

const ChevronLeftThin = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43435 9.41036C8.10891 9.73579 8.10892 10.2634 8.43435 10.5889L13.3333 15.4878C13.6587 15.8132 13.6587 16.3408 13.3333 16.6663C13.0078 16.9917 12.4802 16.9917 12.1547 16.6663L6.07733 10.5889C5.75189 10.2634 5.75189 9.73579 6.07733 9.41036L12.1547 3.33294C12.4802 3.00751 13.0078 3.00751 13.3333 3.33295C13.6587 3.65838 13.6587 4.18602 13.3333 4.51146L8.43435 9.41036Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default ChevronLeftThin
