import React from 'react'
import {variant} from 'styled-system'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import {ICON} from './constants'
import {Icon} from './index'

import theme from './theme'

const TipBase = styled.div`
  border-radius: 16px;
  padding: 12px 16px;
  margin-top: 24px;
  display: flex;
  column-gap: 12px;

  & > svg {
    min-width: fit-content;
  }

  font-size: 16px;
  line-height: 20px;

  ${variant({
    variants: {
      error: {
        color: 'opacity.blanketDark',
        bg: 'opacity.errorBase10',
      },
      warning: {
        color: 'opacity.blanketDark',
        bg: 'opacity.pendingBase10',
      },
      success: {
        color: 'opacity.blanketDark',
        bg: 'opacity.positiveBase10',
      },
      info: {
        color: 'opacity.blanketDark',
        bg: 'opacity.primary10',
      },
      clear: {
        color: 'mono.dark1',
      },
    },
  })}

  ${ICON}
`

const TipIcon = styled.div`
  ${variant({
    variants: {
      error: {
        color: 'color.errorBase',
      },
      warning: {
        color: 'color.pendingDark',
      },
      success: {
        color: 'color.positiveBase',
      },
      info: {
        color: 'color.primaryBase',
      },
      clear: {
        color: 'color.positiveBase',
      },
    },
  })}

  & > svg {
    fill: currentColor;
  }
`

const Tip = (props) => {
  const {icon, children, ...baseProps} = props
  return (
    <TipBase {...baseProps}>
      <TipIcon variant={baseProps.variant} theme={baseProps.theme}>
        <Icon icon={icon} />
      </TipIcon>
      {children}
    </TipBase>
  )
}

Tip.defaultProps = {
  theme,
  variant: 'info',
  icon: 'exclamation-mark-circled',
}

Tip.propTypes = {
  ...ICON.propTypes,
  variant: PropTypes.oneOf(['error', 'warning', 'success', 'info', 'clear']),
}

export default Tip
