import React from 'react'

const Bin = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M7.5 1.668h5c.92 0 1.666.746 1.666 1.667v.833h3.333a.833.833 0 1 1 0 1.667h-.76l-.076.902-.83 9.931c0 .92-.747 1.667-1.667 1.667H5.833c-.92 0-1.667-.747-1.664-1.598L3.26 5.835H2.5a.833.833 0 0 1 0-1.667h3.333v-.833c0-.92.746-1.667 1.666-1.667ZM5.07 5.835l.762 10.833h8.333l.003-.082.758-10.751H5.071Zm7.428-2.5v.833h-5v-.833h5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Bin
