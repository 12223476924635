import React from 'react'

const ChevronRightThin = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5656 10.5889C11.891 10.2634 11.891 9.73579 11.5656 9.41036L6.66668 4.51146C6.34125 4.18602 6.34125 3.65838 6.66669 3.33294C6.99212 3.00751 7.51976 3.00751 7.8452 3.33294L13.9226 9.41036C14.248 9.73579 14.248 10.2634 13.9226 10.5889L7.8452 16.6663C7.51976 16.9917 6.99212 16.9917 6.66669 16.6663C6.34125 16.3408 6.34125 15.8132 6.66669 15.4878L11.5656 10.5889Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default ChevronRightThin
