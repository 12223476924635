import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import {variant} from 'styled-system'

import sx from './sx'
import {TYPOGRAPHY, COMMON, BORDER, LAYOUT, ICON} from './constants'
import theme from './theme'
import {Icon} from './index'

const TabSwitch = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border: none;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${({disabled, active}) =>
    variant({
      variants: {
        folderType: {
          ...(active
            ? {
                marginBottom: '-2px',
                bg: 'transparent',
                color: disabled ? 'mono.shade2' : 'mono.dark2',
                borderBottom: '2px solid',
                borderColor: disabled ? 'mono.shade1' : 'mono.shade3',
              }
            : {
                marginBottom: '-2px',
                bg: 'transparent',
                color: disabled ? 'mono.shade2' : 'mono.dark2',
                borderBottom: '2px solid',
                borderColor: disabled ? 'opacity.dark5' : 'transparent',
                '&:hover': {
                  color: disabled ? 'mono.shade2' : 'mono.dark1',
                  bg: disabled ? 'transparent' : 'opacity.primary5',
                  borderColor: disabled ? 'opacity.dark5' : 'opacity.primary10',
                },
                '&:active': {
                  color: disabled ? 'mono.shade2' : 'mono.dark2',
                  bg: 'transparent',
                  borderColor: disabled ? 'opacity.dark5' : 'mono.shade3',
                },
              }),
        },
      },
    })}
  ${variant({
    prop: 'scale',
    variants: {
      sm: {
        py: ['5px'],
        px: ['16px'],
        fontSize: [1],
        lineHeight: [2],
        '& > svg': {
          size: ['16px'],
          marginRight: ['6px'],
        },
      },
      md: {
        py: ['5px', '5px', '5px', '9px'],
        px: ['16px', '16px', '16px', '20px'],
        fontSize: [1, 1, 1, 2],
        lineHeight: [2, 2, 2, 2],
        '& > svg': {
          size: ['16px', '16px', '16px', '20px'],
          marginRight: ['6px', '6px', '6px', '6px'],
        },
      },
      lg: {
        py: ['9px', '9px', '9px', '13px'],
        px: ['20px', '20px', '20px', '24px'],
        fontSize: [2, 2, 2, 4],
        lineHeight: [2, 2, 2, 2],
        '& > svg': {
          size: ['20px', '20px', '20px', '20px'],
          marginRight: ['6px', '6px', '6px', '8px'],
        },
      },
    },
  })}

  ${LAYOUT}
`

const TabSwitcher = styled.div`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  border-bottom: 2px solid ${(props) => props.theme.colors.opacity.primary10};
  
  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  ${LAYOUT}
  ${ICON}
  ${sx}
`

const TabSwitcherGroup = (props) => {
  const {tabs = [], onTabClick = () => {}, minTabWidth = 'unset', variant, scale, theme, ...baseProps} = props

  return (
    <TabSwitcher theme={theme} {...baseProps}>
      {tabs.map((tab) => {
        const {name, icon, active, disabled} = tab

        return (
          <TabSwitch
            key={name}
            theme={theme}
            scale={scale}
            active={active}
            variant={variant}
            disabled={disabled}
            minWidth={minTabWidth}
            onClick={() => onTabClick(tab)}
          >
            {icon && <Icon icon={icon} />}
            {name}
          </TabSwitch>
        )
      })}
    </TabSwitcher>
  )
}

TabSwitcherGroup.defaultProps = {
  theme,
  scale: 'md',
  variant: 'folderType',
  fontFamily: 'sourceSans3',
}

TabSwitcherGroup.propTypes = {
  ...TYPOGRAPHY.propTypes,
  ...COMMON.propTypes,
  ...BORDER.propTypes,
  ...LAYOUT.propTypes,
  ...ICON.propTypes,
  ...sx.propTypes,
  scale: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['folderType']),
}

export default TabSwitcherGroup
