import React from 'react'

const PlusCircled = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.1663C4.93743 19.1663 0.833374 15.0623 0.833374 9.99967C0.833374 4.93706 4.93743 0.833008 10 0.833008C15.0626 0.833008 19.1667 4.93706 19.1667 9.99967C19.1667 15.0623 15.0626 19.1663 10 19.1663ZM10 17.4997C14.1422 17.4997 17.5 14.1418 17.5 9.99967C17.5 5.85754 14.1422 2.49967 10 2.49967C5.8579 2.49967 2.50004 5.85754 2.50004 9.99967C2.50004 14.1418 5.8579 17.4997 10 17.4997ZM13.3334 9.16634H10.8334V6.66634C10.8334 6.2061 10.4603 5.83301 10 5.83301C9.5398 5.83301 9.16671 6.2061 9.16671 6.66634V9.16634H6.66671C6.20647 9.16634 5.83337 9.53944 5.83337 9.99967C5.83337 10.4599 6.20647 10.833 6.66671 10.833H9.16671V13.333C9.16671 13.7932 9.5398 14.1663 10 14.1663C10.4603 14.1663 10.8334 13.7932 10.8334 13.333V10.833H13.3334C13.7936 10.833 14.1667 10.4599 14.1667 9.99967C14.1667 9.53944 13.7936 9.16634 13.3334 9.16634Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PlusCircled
