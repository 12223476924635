import React from 'react'
import styled from 'styled-components/macro'

import {Icons} from './constants'
import theme from '../theme'
import {COMMON, ICON, LAYOUT} from '../constants'
import sx from '../sx'

const IconBase = styled.div`
  ${ICON}
  ${COMMON}
  ${LAYOUT}
  ${sx}
`

const Icon = (props) => {
  const IconAs = Icons[props.icon] || 'div'

  return <IconBase as={IconAs} {...props} />
}

Icon.defaultProps = {
  theme,
}

Icon.propTypes = {
  ...COMMON.propTypes,
  ...sx.propTypes,
  icon: ICON.propTypes.icon.isRequired,
}

export default Icon
